import lozad from '../../_snowpack/pkg/lozad.js';

// Enable lazy loading of images using the lozad library
// This works because the javascript is called after the dom nodes have been created
// It would fail if called in the <head> section as the nodes are not created yet
const els = document.querySelectorAll('.js-lazy');
const observer = lozad(els);
observer.observe();

// redirect to the language matching the browser configuration
document.addEventListener("DOMContentLoaded", () => {
  if (document.body.classList.contains('index')) {
    var lang = navigator.language || navigator.userLanguage;
    if (lang === 'fr-FR') {
      window.location.href = `${window.location}fr`;
    } else {
      window.location.href = `${window.location}en`;
    }
  }
});


// LANGAGE SWITCH BUTTON

const langSwitchContainer = document.querySelector('.lang-switch-container')

const langChoiceContainer = document.querySelector('.lang-choice-container')

let switchContainerOpen = false


    if(document.documentElement.clientWidth  >= 750){
      langSwitchContainer.addEventListener(
      'mouseover',
      ()=>{
          langChoiceContainer.style.display="flex"
          langChoiceContainer.style.top="100%"
          langChoiceContainer.style.opacity="1"
  
          langSwitchContainer.addEventListener(
            'mouseout',
            ()=>{
                langChoiceContainer.style.top="0"
                langChoiceContainer.style.opacity="0"
            }
        )
      }
      )
    }
    else{
        langSwitchContainer.addEventListener(
        'click',
        ()=>{
          if(switchContainerOpen == false){
            langChoiceContainer.style.display="flex"
            langChoiceContainer.style.top="100%"
            langChoiceContainer.style.opacity="1"
            switchContainerOpen = true
          }
          else{
            langChoiceContainer.style.top="0"
            langChoiceContainer.style.opacity="0"
            switchContainerOpen = false
          }
        }
      )
    }

